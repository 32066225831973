const grays = ["#E8E8E8", "#707070", "#000000CC", "#393739"];

const brand = {
  blue: "#0B42E0",
  darkBlue: "#106CAA",
  lightGrey: "#f6f6f6",
  black: "#333333",
  green: "#8FCA45",
  "outline-green-blue": "#1B9AD6",
  "outline-green": "#C7EA8B",
  lightBlue: "#1B9AD6",
  greyBlue: "#D1DBF0",
  lightGreen: "#C7EA8B",
  lightGreen2: "#DEEDB0",
  blueGradient: "linear-gradient(to bottom, #1B9AD6, white)",
  greenGradient: "linear-gradient(to bottom, #C7EA8B, white)",
};
export const colors = {
  text: brand.black,
  grayText: "#383838",
  background: "#fff",
  primary: brand.darkBlue,
  secondary: brand.blue,
  accent: "#044c8a",
  placeholder: "#707070",
  brand,
  green: brand.green,
  lightGreen: brand.lightGreen,
  lightGreen2: brand.lightGreen2,
  lightBlue: brand.lightBlue,
  darkBlue: brand.darkBlue,
  blueGradient: brand.blueGradient,
  greenGradient: brand.greenGradient,
  black: brand.black,
  blue: brand.blue,
};
