import { useState, useEffect, useRef } from "react";
import axios from "axios";
export interface Day {
  id: number;
  dow: string;
  hours: string;
}

export interface Hours {
  days: Day[];
  isOpen: string;
  openClosedTime: string;
}

export interface Holiday {
  day: Date;
  name: string;
}

export interface Hour {
  num: number;
  hour: string;
}

export interface StoreDay {
  openHour: number;
  openMin: number;
  closeHour: number;
  closeMin: number;
}

export interface StoreDays {
  [sunday: string]: StoreDay;
  monday: StoreDay;
  tuesday: StoreDay;
  wednesday: StoreDay;
  thursday: StoreDay;
  friday: StoreDay;
  saturday: StoreDay;
}

export interface StoreHours {
  days: StoreDays;
}

export interface DayOfTheWeekShort {
  num: number;
  day: string;
}

const getNextDayIsOpen = (nextDay: number, hoursDetailed: Hours) => {
  return hoursDetailed.days.find((day: Day) => day.id == nextDay);
};

const getThanksgivingDay = (
  daysOfTheWeekShort: DayOfTheWeekShort[],
  currentDate: Date
) => {
  const year = currentDate.getFullYear();
  const novFirstDate = new Date(year, 10, 1);
  const novFirstDayNum = novFirstDate.getDay();
  const thursdayNum = daysOfTheWeekShort.find((day) => day.day == "Thu")?.num;
  let fourthThurs = null;
  if (thursdayNum && novFirstDayNum > thursdayNum) {
    if (novFirstDayNum == 5) {
      fourthThurs = new Date(year, 10, 1 + 6 + 21);
    } else if (novFirstDayNum == 6) {
      fourthThurs = new Date(year, 10, 1 + 5 + 21);
    }
  } else if (thursdayNum && novFirstDayNum <= thursdayNum) {
    fourthThurs = new Date(year, 10, 1 + (thursdayNum - novFirstDayNum) + 21);
  }
  return fourthThurs;
};

const getNextOpenDay = (
  hoursDetailed: Hours,
  holidays: Holiday[],
  currentDate: Date
) => {
  let nextOpenDay: Day | undefined;
  let count = currentDate.getDay();
  let tempDay: Day | undefined;
  let date: Date;
  let day;
  let currentHoursArray = [];
  let isHoliday = false;
  while (!nextOpenDay) {
    if (count !== 6) {
      count++;
    } else {
      count = 0;
    }
    if (count > currentDate.getDay()) {
      day = currentDate.getDate() + (count - currentDate.getDay());
    } else if (count < currentDate.getDay()) {
      day = 6 - currentDate.getDay() + count + currentDate.getDate() + 1;
    } else {
      getNextOpenDay(
        hoursDetailed,
        holidays,
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDay() + 7
        )
      );
    }
    date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    isHoliday = holidays.find(
      (holiday: Holiday) =>
        holiday.day.getFullYear() == date.getFullYear() &&
        holiday.day.getMonth() == date.getMonth() &&
        holiday.day.getDay() == date.getDay()
    )
      ? true
      : false;
    if (!isHoliday) {
      tempDay = getNextDayIsOpen(count, hoursDetailed);
    }
    if (tempDay) {
      nextOpenDay = tempDay;
    }
  }
  if (
    count == currentDate.getDay() + 1 ||
    (count == 0 && currentDate.getDay() == 6)
  ) {
    currentHoursArray = nextOpenDay.hours.split("-");
    return currentHoursArray[0];
  } else {
    currentHoursArray = nextOpenDay.hours.split("-");
    return currentHoursArray[0] + " on " + nextOpenDay.dow;
  }
};

export const _hoursDetailed = (storeHours: StoreDays, isOpen: boolean) => {
  const hoursDetailed: Hours = {
    days: [],
    isOpen: isOpen ? "Closed" : "Open",
    openClosedTime: "N/A",
  };
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const daysOfTheWeekShort = [
    {
      num: 0,
      day: "Sun",
    },
    {
      num: 1,
      day: "Mon",
    },
    {
      num: 2,
      day: "Tue",
    },
    {
      num: 3,
      day: "Wed",
    },
    {
      num: 4,
      day: "Thu",
    },
    {
      num: 4,
      day: "Thurs",
    },
    {
      num: 5,
      day: "Fri",
    },
    {
      num: 6,
      day: "Sat",
    },
  ];
  const hoursInDay = [
    {
      num: 0,
      hour: "12am",
    },
    {
      num: 1,
      hour: "1am",
    },
    {
      num: 2,
      hour: "2am",
    },
    {
      num: 3,
      hour: "3am",
    },
    {
      num: 4,
      hour: "4am",
    },
    {
      num: 5,
      hour: "5am",
    },
    {
      num: 6,
      hour: "6am",
    },
    {
      num: 7,
      hour: "7am",
    },
    {
      num: 8,
      hour: "8am",
    },
    {
      num: 9,
      hour: "9am",
    },
    {
      num: 10,
      hour: "10am",
    },
    {
      num: 11,
      hour: "11am",
    },
    {
      num: 12,
      hour: "12pm",
    },
    {
      num: 13,
      hour: "1pm",
    },
    {
      num: 14,
      hour: "2pm",
    },
    {
      num: 15,
      hour: "3pm",
    },
    {
      num: 16,
      hour: "4pm",
    },
    {
      num: 17,
      hour: "5pm",
    },
    {
      num: 18,
      hour: "6pm",
    },
    {
      num: 19,
      hour: "7pm",
    },
    {
      num: 20,
      hour: "8pm",
    },
    {
      num: 21,
      hour: "9pm",
    },
    {
      num: 22,
      hour: "10pm",
    },
    {
      num: 23,
      hour: "11pm",
    },
  ];
  const currentDate = new Date();
  const thanksGivingDay = getThanksgivingDay(daysOfTheWeekShort, currentDate);
  const holidays: Holiday[] = [
    {
      day: new Date(currentDate.getFullYear(), 11, 25),
      name: "Christmas",
    },
    {
      day: thanksGivingDay ? thanksGivingDay : new Date(0),
      name: "Thanksgiving",
    },
  ];
  for (const [day, value] of Object.entries(storeHours)) {
    const openHour = hoursInDay.find((hour) => value.openHour == hour.num);
    const closeHour = hoursInDay.find((hour) => value.closeHour == hour.num);
    const hours =
      (openHour ? openHour.hour : "") + "-" + (closeHour ? closeHour.hour : "");
    hoursDetailed.days.push({
      id: weekDays.indexOf(day),
      dow: day,
      hours: hours,
    });
  }
  const today: Day | undefined = hoursDetailed.days.find(
    (day: Day) => weekDays.indexOf(day.dow) == currentDate.getDay()
  );
  let currentHours = null;
  let currentHoursArray: Array<string> = [];
  let currentHoliday: Holiday = {
    day: new Date(),
    name: "",
  };
  if (today) {
    currentHours = today.hours;
    currentHoursArray = currentHours.split("-");
    currentHoliday = holidays.find(
      (holiday: Holiday) =>
        holiday.day.getFullYear() == currentDate.getFullYear() &&
        holiday.day.getMonth() == currentDate.getMonth() &&
        holiday.day.getDay() == currentDate.getDay()
    ) as Holiday;
    if (currentHoliday) {
      hoursDetailed.isOpen = "Closed for " + currentHoliday.name;
      hoursDetailed.openClosedTime =
        "Opens at " + getNextOpenDay(hoursDetailed, holidays, currentDate);
    } else if (
      currentDate.getHours() >= storeHours[today.dow].openHour &&
      currentDate.getHours() < storeHours[today.dow].closeHour
    ) {
      hoursDetailed.isOpen = "Open";
      hoursDetailed.openClosedTime = "Closes at " + currentHoursArray[1];
    } else if (currentDate.getHours() < storeHours[today.dow].openHour) {
      hoursDetailed.isOpen = "Closed";
      if (currentHoursArray) {
        hoursDetailed.openClosedTime = "Opens at " + currentHoursArray[0];
      } else {
        hoursDetailed.openClosedTime =
          "Opens at " + getNextOpenDay(hoursDetailed, holidays, currentDate);
      }
    } else {
      hoursDetailed.isOpen = "Closed";
      hoursDetailed.openClosedTime =
        "Opens at " + getNextOpenDay(hoursDetailed, holidays, currentDate);
    }
  } else {
    hoursDetailed.isOpen = "Closed";
    hoursDetailed.openClosedTime =
      "Opens at " + getNextOpenDay(hoursDetailed, holidays, currentDate);
  }
  return hoursDetailed;
};

export const commafy = (amount: number) => {
  if (!amount) return amount;
  const str = amount.toString().split(".");
  if (str[0].length >= 4) {
    // adds a comma after any digit that's followed by one or more groups of 3 digits
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  return str.join(".");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areEqualShallow = (a: any, b: any) => {
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (const key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePrevious = (value: any) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const removeUnnecessaryFromPath = (str: string) => {
  const secondSlashIndex = str.indexOf("/", str.indexOf("/") + 1);
  return str.slice(secondSlashIndex).slice(0, -1);
};

export const capitalizeNouns = (str: string) => {
  const words = str.replace("/", "").replace(/-/g, " ").split(" ");
  const nounRegex = /^[A-Z]?((?!of\b)[a-z])*$/; // regular expression to match nouns

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (nounRegex.test(word)) {
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
  }
  return words.join(" ");
};

export const getTrustPilotReviews = async () => {
  try {
    const apiKey = process.env.GATSBY_TRUST_PILOT_API_KEY;
    const businessResponse = await axios.get(
      "https://api.trustpilot.com/v1/business-units/5e72238d600d1a0001be01eb",
      {
        headers: { ApiKey: apiKey },
      }
    );
    const businessData = businessResponse.data;

    const recentResponse = await axios.get(
      "https://api.trustpilot.com/v1/business-units/5e72238d600d1a0001be01eb/reviews?stars=5&includeReportedReviews=true",
      {
        headers: { ApiKey: apiKey },
      }
    );

    const recentData = recentResponse.data;

    return {
      business: businessData,
      allReviews: recentData,
    };
  } catch (error) {
    console.error(error);
    // throw error; // rethrow the error to handle it outside this function if needed
  }
};

export const formatReviewName = (name: string) => {
  const nameSplit = name.split(" ");
  return `${nameSplit[0]} ${
    nameSplit.length == 2 ? nameSplit[1].split("")[0] + ". " : " "
  }`;
};

export const addCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
