import React, { ReactNode, useEffect, useState } from "react";
import { getTrustPilotReviews } from "../utils/utils";

export interface Review {
  createdAt: string;
  id: string;
  stars: number;
  text: string;
  title: string;
  reviewVerificationLevel: string;
  consumer: {
    displayName: string;
  };
}
export interface Reviews {
  business: {
    numberOfReviews: {
      usedForTrustScoreCalculation: number;
      total: number;
    };
    score: {
      stars: number;
      trustScore: number;
    };
  };
  allReviews: {
    reviews: Review[];
  };
}
export type ContextType = Reviews;
const defaultState = {};

// numberOfReviews: {
//   usedForTrustScoreCalculation: number;
//   total: number;
// };
// score: {
//   stars: number;
//   trustScore: number;
// };
export const TrustPilotContext = React.createContext(defaultState);

export const TrustPilotProvider = ({ children }: { children: ReactNode }) => {
  const [reviews, setReviews] = useState<Reviews | null>(null);

  useEffect(() => {
    const asyncFunc = async () => {
      const data = await getTrustPilotReviews();
      setReviews(data);
    };
    asyncFunc();
  }, []);

  return (
    <TrustPilotContext.Provider value={{ ...reviews }}>
      {children}
    </TrustPilotContext.Provider>
  );
};
