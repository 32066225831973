import { colors } from "./colors";
import * as variants from "./variants";
import * as type from "./type";

const defaultTheme = {
  breakpoints: ["40em", "48em", "64em", "80em", "92em", "112em"],
  colors,
  ...variants,
  ...type,
  // layout: {
  //   container: {
  //     px: [3, 4],
  //     py: 3,
  //     width: "100%",
  //     minWidth: 0,
  //     maxWidth: "1024px",
  //     marginLeft: "auto",
  //     marginRight: "auto",
  //   },
  // },
  styles: {
    root: {
      margin: "0",
      fontFamily: "body",
      p: {
        maxWidth: "1200px",
        lineHeight: ["28px", null, null, "32px"],
        fontSize: [18, null, null, null, 22],
      },
      h1: {
        lineHeight: [1.2],
        fontWeight: "semibold",
        fontSize: [32, null, null, 40, 55, 65],
      },
      h2: {
        fontWeight: "semibold",
        fontSize: [28, null, null, 32, 36, 42],
      },
      iframe: {
        border: "none",
        borderColor: "none",
      },
      "button:active": {
        outline: "none",
        border: "none",
      },
      "button:focus": {
        outline: "none",
        border: "none",
      },
      a: {
        cursor: "pointer",
        fontSize: [1, null, null, 2],
      },
      input: {
        fontFamily: "Montserrat",
      },
      textarea: {
        fontFamily: "Montserrat",
      },
    },
    // img: {
    //   maxWidth: "100%",
    // },
    // p: {
    //   fontSize: "1rem",
    //   lineHeight: "1.5",
    // },
    // fieldset: {
    //   border: "0",
    // },
    // small: {
    //   fontSize: "0.625rem",
    // },
    // 'input[type="number"]': {
    //   appearance: "textField",
    // },
    // 'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
    //   {
    //     appearance: "none",
    //     margin: 0,
    //   },
  },
};

export default defaultTheme;
